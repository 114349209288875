import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Privacy = () => {
  return (
    <>
    <Navbar/>
    <div className="container prv-term-cookie privacy-container px-4 py-5 float-none" id="featured-3" bis_skin_checked="1">
        <h2 className="pb-2 border-bottom">Privacy Policy</h2> <div className="row g-4 py-5 row-cols-1 row-cols-lg-1" bis_skin_checked="1"> <p>
        This is the privacy of socialgames333.com. It applies to this
                    website, socialgames333.com.<br/>In this policy, the terms “you”,
                    “your” and “your” refer to you, the user of this website or any
                    other guest, person or organization who access or use this website
                    or services from the website. and the terms “we”, “us”, “our” or
                    “our” refer to socialgames333.com.
        </p> <p>
        INFORMATION WE COLLECT<br/>“Personal Information” refers to
                    information that can be used to identify you. We collect personal
                    data that you voluntarily provide to us. Some examples of personal
                    information we may collect are your name, date of birth, postal
                    address, email address, phone number, gender, gaming activity, and
                    purchase and reservation information. When you visit
        socialgames333.com, personal information may include
                    your picture in a live video feed that can be displayed on the
        socialgames333.com websites. Some personal information is considered
                    “confidential personal information”. Confidential personal
                    information is your name combined with one or more of the following:
                    credit or debit card number, financial account number, driver’s
                    license number, government ID number, passport number, and
                    naturalization number.<br/>In addition to the personal information
                    that you voluntarily provide to us, we may receive personal
                    information from third parties, including, but not limited to,
                    business partners, family members, friends and organizations that
                    you submit to, or are on, or through the website make available
                    page? In situations where your personal information is collected by
                    third parties under a contract with us, those third parties must use
                    reasonable care to protect your personal information and to limit
                    their use of your personal information to the purposes for which it
                    was provided .<br/>“Non-Personally Identifiable Information” refers
                    to information about you or your activities that cannot be used to
                    personally identify you. When you use the Internet, your web browser
                    or software may transmit certain information to the servers that
                    hold the websites you visit. This information may include, for
                    example, the unique number assigned to your server or Internet
                    connection, your geographic location, and your interactions with the
                    site, including the pages you viewed and the information you
                    downloaded. When you visit the website, your non-personal
                    information may be collected. Non-personal data can be linked to
                    personal data.<br/>When you visit the website, we use “cookies” and
                    similar technologies so that we can better recognize you and serve
                    you better. Cookies are small files that contain information sent by
                    a website that is stored on your computer’s hard drive. Cookies
                    enable us to collect data such as websites visited, links clicked
                    and material downloads. Cookies are also used to place a unique
                    identifier on your computer. In this way we can compile statistics
                    on the use of the socialgames333.com websites, e.g. B. the
                    percentage of returning visitors. In situations where this is
                    possible, this unique identifier can be linked to personal
                    information.<br/>There are several types of cookies, including
                    browser cookies (also known as HTML cookies) and Flash cookies (also
                    known as local shared objects). You can configure your browser so
                    that it rejects browser cookies or notifies you when browser cookies
                    are offered so that you can accept or reject such cookies. However,
                    if you refuse browser cookies, you may not be able to carry out some
                    transactions or use some services on the website. However, blocking
                    or deleting browser cookies must not block or delete other types of
                    cookies, including flash cookies.<br/>Third-party advertising
                    companies that work with us may also use cookies and similar
                    technologies to collect Non-Personally Identifiable Information on
                    the Website and other websites for a variety of purposes, including
                    serving advertisements that are likely to be of interest to you.
                    This type of ad customization is sometimes called “behavioral
                    advertising”.
        </p> <p>
        HOW WE USE THE INFORMATION COLLECTED<br/>With the exception of
                    Confidential Personal Information, we may use Personal Information
                    and Non-Personal Information for our own marketing purposes,
                    including, but not limited to, notifying you of special promotions,
                    offers and events by mail, email, phone or SMS and other means.
                    Confidential personal data will not be used for marketing purposes
                    without your express consent. We can also use personal data and
                    non-personal data for non-marketing purposes. Some examples of such
                    use include (1) processing and fulfilling purchases; (2) performing
                    statistical or demographic analyzes; (3) customize your experience
                    on the site; and (4) Answering your inquiries or other
                    communications. We may combine personal data with other generally or
                    publicly available information to determine your preferences or
                    interests. Your personal information can also be merged with
                    personal information obtained from other sources such as (1)
                    Companies that match email addresses with postal addresses and other
                    information; (2) credit card verification and credit services; and
                    (3) other resorts, casinos or real estate that are owned, operated
                    by or connected to socialgames333.com. If you do not want us to use
                    your personal data for marketing purposes, you can deactivate this
                    use by notifying us in accordance with Section III.A. below.
                    Disabling it does not prevent your personal information from being
                    used for non-marketing purposes. Share with business partners and
                    other third parties With the exception of confidential personal
                    data, we may pass on your personal data to our business partners for
                    joint marketing efforts or for our business partners’ own marketing
                    purposes. When we provide our business partners with personal
                    information, they must exercise reasonable care to protect your
                    personal information and to limit the use of your personal
                    information to the purposes for which it was made available to them.
                    If you do not want us to pass your personal data on to business
                    partners for marketing purposes, you can deactivate this transfer by
                    notifying us in accordance with Section III.B. below. Although you
                    may request that we refuse to share personal information with our
                    business partners for marketing purposes, we may share personal
                    information with our business partners and other third parties for
                    the following purposes: for non-marketing purposes, such as
                    processing and fulfilling reservations or purchases; Comply with a
                    court order, subpoena, search warrant or other applicable legal
                    process; Comply with any legal, regulatory, or administrative
                    requirements of any government agency; protect and defend ourselves
                    or our affiliates and all of their officers, directors, employees,
                    attorneys and agents in connection with any legal action, claim or
                    dispute; to fulfill a contractual obligation to compensate our
                    contractors, business partners or other third parties in connection
                    with legal action, claims or disputes; enforce the website’s terms
                    of use; prevent imminent physical harm or threats to public safety;
                    Prevent fraud.
        </p> <p>
        YOUR COMMUNICATION PREFERENCES<br/>How to deactivate yourself<br/>If you would like to unsubscribe from receiving inquiries from us
                    or if you do not want us to pass your personal data on to our
                    business partners for marketing purposes, please send us an email at
                    [email protected]<br/>Telemarketing<br/>Even if you have
                    registered your phone number in a call log, we can contact you by
                    phone due to certain exceptions. To ensure that our customers’
                    preferences are taken into account, we also keep our own internal
                    call list. If you would like to unsubscribe from receiving
                    telemarketing sales calls from us by adding your phone number to our
                    internal no-call list, please email us at [email protected]
        </p> <p>
        SAFETY<br/>Personal and non-personal information that we collect is
                    stored on secure servers that are protected by firewalls and other
                    industry-standard security measures. These security measures are
                    intended to protect our servers from unauthorized access. However,
                    no security system is impenetrable, and these systems could become
                    accessible in the event of a security breach. We have controls in
                    place to identify potential data breaches, contain and minimize data
                    loss, and conduct forensic investigations into violations.<br/>Our
                    employees must take reasonable steps to ensure that unauthorized
                    persons cannot view or access your personal information. Employees
                    who violate our privacy policy are subject to disciplinary action up
                    to and including termination.<br/>We cannot enforce or control the
                    security of the computers, electronic devices, or electronic
                    communication methods that you use to send emails and send
                    information to us over the Internet. You are responsible for
                    ensuring that the computers, electronic devices and electronic
                    communication methods you use provide reasonable security for
                    communicating with us. We are not responsible for any disclosure or
                    interception of any information you send us before we receive it.<br/>As a standard security practice, we will take reasonable steps
                    that are industry standard to ensure that the communication methods
                    used to support the Site do not allow any connection or
                    communication with methods known to be vulnerable or vulnerable. If
                    you are having problems using the site, it may indicate that you
                    need to update your internet browsing tool or application to a newer
                    version that supports more secure communication methods.
        </p> <p>
        RESPONSIBLE GAME PROGRAM<br/>socialgames333.com adheres to its code
                    of conduct for responsible gaming. Accordingly, we will not use the
                    personal information of members of Self-Limit Access programs for
                    marketing purposes.
        </p> <p>
        OTHER WEBSITES<br/>We are not responsible for the information
                    gathered or the privacy practices of other websites, including
                    websites operated by our business partners or other third parties.
                    You should review and understand the privacy policies posted on the
                    linked websites you visit before using those websites or providing
                    any personal information.
        </p> <p>
        PEOPLE AGED 18 AND 21<br/>Certain of our areas and activities are
                    intended for people aged 21 and over. If you are under the age of
                    21, we ask that you do not access, attempt to participate in, or
                    participate in any areas that are restricted to those aged 21 or
                    over. We do not knowingly collect or allow the collection of
                    personal information from anyone under the age of 18. If you are the
                    parent or legal guardian of someone under the age of 18 and you
                    believe that the person has provided us with personal information,
                    you can email us at [email protected].
        </p> <p>
        CHANGES TO THIS POLICY<br/>We reserve the right to change this
                    policy at any time without notice. If this policy is materially
                    revised, a notification will be posted on the website with the
                    revised policy.
        </p> </div> </div>
    <Footer/>
    </>
  )
}

export default Privacy